import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Picture } from "../components/primitives"
import BackgroundImage, { IFluidObject } from "gatsby-background-image"
import {
  GatsbyImageSharpFluid_WithWebpFragment,
  HeaderQuery,
} from "../../graphql-types"

type Props = {
  fluid?: GatsbyImageSharpFluid_WithWebpFragment | undefined
  title?: string
}

export const Header: React.FC<Props> = ({ fluid, title }) => {
  const data: HeaderQuery = useStaticQuery(graphql`
    query Header {
      header: file(relativePath: { eq: "_DSC0763.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 600, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  if (fluid || data.header?.childImageSharp?.fluid) {
    return (
      <Picture>
        <BackgroundImage
          fluid={
            (fluid as IFluidObject) ||
            (data.header?.childImageSharp?.fluid as IFluidObject)
          }
          preserveStackingContext={true}
        />
        {title && (
          <div
            css={`
              position: absolute;
              left: 0;
              top: 0;
              padding-top: 6rem;
              padding-bottom: 3rem;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              opacity: 1 !important;

              h1 {
                color: ${props => props.theme.colors.white};
                font-family: ${props => props.theme.fonts.heading};
                opacity: 1 !important;
                font-size: calc(3rem + 2vw);
              }
            `}
          >
            <h1>{title}</h1>
          </div>
        )}
      </Picture>
    )
  }
  return null
}

export default Header
